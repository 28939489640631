import '../Styles/App.css';
import { Layout } from './CommonComponents.js'
//import FullCalendar from '@fullcalendar/react' // must go before plugins
//import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

  function Classes() {
    // let events = [
    //     {
    //         daysOfWeek: [ '3' ],
    //         title: 'Beginners',
    //         startTime: '09:30:00',
    //         endTime: '10:40:00',
    //         color: 'red'
    //     },
    //     {
    //         daysOfWeek: [ '6' ],
    //         title: 'Intermediate',
    //         startTime: '08:30:00',
    //         endTime: '09:40:00',
    //         color: 'red'
    //     }
    // ];

    // let toolBar = {
    //     left: 'prev,next',
    //     center: 'title',
    //     right: 'dayGridWeek,dayGridDay' // user can switch between the two
    // };

    return (
      	<Layout header={'CLASSES'}>
			<div>		

				{/* <FullCalendar
					plugins={[ dayGridPlugin ]}
					initialView= { 'dayGridWeek' }
					headerToolbar= { toolBar }
					events={events}
					editable={false}
					height={'auto'}
				/> */}

				<h3>Timetable</h3>
				<ul className="noBulletList noIndent">
					<li>
						Wednesday 9:30 am to 10:45 am
					</li>
					<li>
						Saturday 8:30 am to 9:45 am
					</li>
					<li>
						If possible please arrive a few minutes before class.
					</li>
				</ul>

				<h3>Cost</h3>
				<ul>
					<li>First introductory class $10</li>
					<li>Casual class $20</li>
					<li>Purchase a 5 Class pass $85</li>
					<li>Purchase a 10 class pass $160</li>
				</ul>
				5 and 10 pass payments last for 6 months from date of purchase and may be extended if required by
				prior arrangement under certain circumstances.
				
				<h3>Payment Methods</h3>
				Payments may be made by direct bank deposit, credit card at the class or by cash.
				For payments by direct deposit please email your bank 
				confirmation to <a href="mailto:michael@preciseyoga.com.au">michael@preciseyoga.com.au</a> referencing your name.
				<br />
				Direct Deposits to:
				<ul className='noBulletList'>
					<li>Name of account: <b>Precise Yoga</b></li>
					<li>Account No: <b>062-692</b></li>
					<li>BSB: <b>7794 1999</b></li>					
				</ul>
			</div>
      	</Layout>
    );
  }

export default Classes;
