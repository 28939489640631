import '../Styles/App.css';
import { Layout } from './CommonComponents.js'

    function Testimonials() {
        return (
            <Layout header={'TESTIMONIALS'}>
                <h4>Still not convinced? Here is what some of the students have to say:</h4>

                <QuoteLeft bgCol="#f2c7c7" auth={"Jan Harper"}>
                    Great for anyone interested in the practise of Iyengar Yoga.
                    Care and attention is paid no matter what level you may be.                   
                </QuoteLeft>

                <QuoteRight bgCol="#f2dcc7" auth={"Alistair Logan"}>
                    Quiet, open space to spend time with other friendly participants as Michael takes us through the
                    gentle to moderate yoga exercises. I have been going to the classes for many years and have really
                    enjoyed the sessions.
                </QuoteRight>

                <QuoteLeft bgCol="#f2efc7" auth={"Birgit Floehermann"}>
                    I've never done Iyengar yoga before and was pleasantly surprised what possibility to move it gave
                    me. Michael is an experienced teacher who guides you gently and patiently into postures to suit
                    your ability. I always feel that I've done something really good for my body and mind.
                </QuoteLeft>

                <QuoteRight bgCol="#d5f2c7" auth={"Judith McCrea"}>
                    I find the classes in Iyengar yoga that Michael teaches have benefited me in flexibility, strength and
                    posture. I always feel revitalised and focused after each class.
                    We have a range of ages and ability in the class I attend.
                    Also, Michael modifies the poses to facilitate any areas of pain during injury recovery for individual
                    students.
                </QuoteRight>

                <QuoteLeft bgCol="#c7f2e1" auth={"Maree Wade"}>
                    I have been attending Michael's classes regularly for most of 2023. He has extensive knowledge of
                    yoga techniques and how yoga assists to connect the body with the mind. He has a gentle manner
                    and encourages me to challenge myself to improve my practice.  As a 68 year old woman I now have
                    more strength and flexibility than I did in my 40's.
                </QuoteLeft>

                <QuoteRight bgCol="#c7e1f2" auth={"Maureen Kable"}>
                    I have really enjoyed my journey with Michael's Iyengar yoga classes. Michael gives clear instructions
                    with options to suit individual needs, if appropriate. In the class each student is encouraged to work
                    to their own capacity in a supportive environment. My level of flexibility and mobility has improved
                    immensely since I have been doing yoga. I also enjoy the social connection with other students.
                </QuoteRight>

                <QuoteLeft bgCol="#c8c7f2" auth={"Bettina Lucini"}>
                    I always feel great after a class with Michael. My back is looser and my mind calmer. I can move
                    more freely. Michael always offers various options for different poses to allow everyone getting the
                    benefits, no matter how the body might feel on the day.
                </QuoteLeft>
                
                <QuoteRight bgCol="#e4c7f2" auth={"Sandra Tuthill"}>
                    It's lovely to be able to attend the yoga classes in such a relaxed environment. There's plenty of
                    space and the equipment is all provided. The yoga classes are extremely reasonably priced as well.
                    Michael is very easy to relate to and structures his classes in a way that draws on his many years of
                    experience as an Iyengar teacher. I find it extremely invigorating yet relaxing, and a great way to
                    start the weekend.
                </QuoteRight>
            </Layout>
        );
    }

    function QuoteLeft({ bgCol, children, auth}) {
        return (
            <div className='testLeft'>
                <p style={{backgroundColor: bgCol}}>
                    <em>"{children}"</em>
                    <br />
                    {auth}
                </p>
            </div>
        );
    }

    function QuoteRight({ bgCol, children, auth}) {
        return (
            <div className='testRight'>
                <p style={{backgroundColor: bgCol}}>
                <em>"{children}"</em>
                    <br />
                    {auth}
                </p>
            </div>
        );
    }

export default Testimonials;
