import React from 'react';
import ReactDOM from 'react-dom/client';
import '../Styles/index.css';
import reportWebVitals from '../reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import { NavBar, Footer } from "../Components/CommonComponents";
import Home from "../Components/IndexComponents";
import Classes from "../Components/ClassesComponents";
import About from "../Components/AboutComponents";
import Teachers from "../Components/TeachersComponents";
import Contact from "../Components/ContactComponents";
import Testimonials from "../Components/TestimonialsComponents";

export function App() {
    return (
        <BrowserRouter>
            <NavBar />
                <Routes>
                    <Route index path='/' element={<Home />} />
                    <Route path="/classes"  element={<Classes />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/teachers" element={<Teachers />} />
                    <Route path="/testimonials" element={<Testimonials />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route element={<Home />} /> 
                </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export function IndexPage() {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}

IndexPage();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
