import '../Styles/App.css';
import { LayoutNoHeader, Quote } from './CommonComponents.js';
import { Link } from "react-router-dom";
import {useLayoutEffect, useRef, useState} from 'react';

    const carouselImg = [
        {},
        {}
    ]

    function Carousel({imgs}) {
        return (
            <>                
                <div id="homeSpinner" className="carousel slide" data-bs-ride="carousel">
                    <div className='imageOverlay'><span>P<span className='titlebody'>RECISE</span> Y<span className='titlebody'>OGA</span></span></div>

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="/YogaWebsite/header.jpg" alt="Header of foggy trees" className="d-block w-100 spinnerImg" />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function Index() {

        const ref = useRef(null);

        const [width, setWidth] = useState(0);
      
        useLayoutEffect(() => {
          setWidth(ref.current.offsetWidth);
        }, []);

        return (
            <>
                <Carousel imgs={carouselImg} />
                <LayoutNoHeader>            
                <div className='row'>
                    <div>
                        <br />
                        <p>
                        Welcome to Precise Yoga, a yoga studio where you can learn and practice yoga, no matter your skill level or physical capabilities in a welcoming and encouraging environment. 
                        With Precise Yoga you're sure to find a class that is perfect for you.
                        </p>

                        <p>
                            Precise Yoga offers two classes per week, at 9.30 am on Wednesday and at 8.30 am on Saturday.
                            Each class lasts for one hour and fifteen minutes.
                        </p>

                        <p>
                            Classes are held at the Uniting Church Hall in Umina, a large modern well-ventilated space that is
                            bright and sunny with wall fans and air conditioning for comfort. All equipment is provided.
                        </p>

                        <p>
                            Our classes are of a general level suitable for men and women of all ages, levels of fitness and yoga
                            experience. We tailor the practice to suit your needs and no previous yoga experience is necessary.
                        </p>

                        <p>
                            See our <Link to="/contact">Contact Us</Link> page for full address and car parking details.
                        </p>

                        <Quote alignType={'center'} reference={ref}>
                            <i>
                                'Yoga is the practice of quieting the fluctuations of the consciousness.'
                            </i>
                            <br />
                            <strong>BKS Iyengar - Light on the yoga sutras of Patanjali</strong>
                        </Quote>

                        <img id='homeImg' src='/YogaWebsite/relaxCropped.jpg' alt='wide shot of people doing yoga' style={{width: width}}/>
                    </div>
                </div>
                </LayoutNoHeader>
            </>
        );
    }

export default Index;
