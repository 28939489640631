import '../Styles/App.css';
import { Layout } from './CommonComponents.js'

    function Contact() {
        return (
            <Layout header={'CONTACT'}>
                <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                        <ul className='noBulletList'>
                            <li>Email: <a href="mailto:michael@preciseyoga.com.au">michael@preciseyoga.com.au</a></li>
                            <li>Phone: <a href="tel:+61 0423 047 097">0423 047 097</a></li>
                            <li>Address: <u>346 Ocean Beach Rd, Umina Beach NSW 2257</u></li>
                            <li style={{marginTop: '10px'}}>
                                    Social Media: 
                                    <div style={{marginRight: "20px", marginLeft: "10px", display: "inline", cursor: "pointer"}}>
                                        <a href='https://www.facebook.com/preciseyogacentralcoast' target="__blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                            </svg> 
                                        </a>
                                    </div>

                                    <div style={{display: "inline", cursor: "pointer"}}>
                                        <a href='https://www.youtube.com/@PreciseYoga' target="__blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                                            </svg>  
                                        </a>
                                    </div>       
                                </li>                
                        </ul>
                        <br />
                        <p>
                            Classes are held in the hall of the Uniting Church 346 Ocean Beach Road, Umina (on the corner of Neptune Street)
                            opposite the Lone Pine Shopping centre. 
                            <br />
                            <br />
                            As Neptune Street is closed at the corner of Ocean Beach Road it is best 
                            to approached the hall from Trafalgar Street. 
                            <br />
                            <br />
                            There is ample parking on site.
                        </p>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <iframe style={{width: "100%", height:"50vh"}} title='LargeGoogleMap' loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJgVGWra9MDWsR-CxpjdxBX6M&key=AIzaSyBH7By8F6AY3zYS2jeqfuWM4EBILJRfuiY"></iframe>
                    </div>                    
                </div>

            
            </Layout>
        );
    }

export default Contact;
