import '../Styles/App.css';
import { Layout } from './CommonComponents.js'

    function Teachers() {
        return (
            <Layout header={'ABOUT'}>
                <TeacherLeft imgSrc={'/YogaWebsite/michaelProfile.jpg'}>
                    <h1>Michael Lever</h1>
                    <div className='justifyDiv'>
                        <p>
                            Michael has been practicing yoga for over 50 years after watching Richard Hittleman's TV series on
                            British TV in the 70s. For many years his practice was self-taught, mainly from books with little
                            opportunity to attend classes in his local area.
                        </p>
                        <p>  
                            Migrating to Newcastle, Australia in 1980 he found a yoga school in the Satyananda tradition and
                            regularly attended classes there. This gentle form of yoga emphasises relaxation, breathing
                            exercises and meditation.
                        </p>
                        <p>  
                            Michael moved to Sydney in the late 80s and had the opportunity to practice Yoga in the Iyengar
                            method, a more physically intense style with emphasis on precise body alignment, and regularly
                            attended classes with senior teachers Alan Goode, Lulu Bull and Pixie Lillas, completing two years of
                            teacher training with Pixie around 2000.
                        </p>
                        <p>  
                            In 2001 Michael moved to the Central Coast for a healthier lifestyle and concentrated on his own
                            home yoga practice as there were no local Iyengar schools at the time.
                        <p> 
                        </p> 
                            In 2017 after practicing alone for many years it was a great relief for Michael to discover Elia's
                            classes in the Iyengar method. Elia formed the Woy Woy Yoga Space and Michael feels it was her
                            encouragement and commitment that were contagious and instrumental in him restarting teacher
                            training. At Elia's suggestion Michael commenced teacher training with Linda Apps, a senior Iyengar
                            teacher in Dulwich Hill in 2019 and is preparing to go for assessment as a certified Iyengar teacher.
                            Michael continues to train regularly with Linda who mentors Michael in his own teacher
                            development.
                        </p>
                        <p>  
                            In January 2022 Michael started teaching a beginners and a men's class at the Woy Woy Yoga Space.
                            Shortly after this The Woy Woy Yoga Space relocated from the studio in Woy Woy to the current
                            location, at the Uniting Church in Umina. It was a great shock and loss to the school and many in
                            the wider Yoga community when in 2022 Elia developed an aggressive form of cancer and had to
                            withdraw from regular teaching. Michael kept the classes going in the hope Elia would recover.
                            Unfortunately Elia passed away on 25 th April 2023. It is a great loss to all who knew Elia and she is
                            sorely missed.
                        </p>
                        <p>
                            Michael cannot thank Elia enough for her encouragement, patience, knowledge, strength and
                            wisdom. Michael believes yoga is unique in that it not only offers a way to maintain optimum health
                            throughout one's lifetime but also recognises that we all have to pass at some time. Michael feels
                            Elia's final teaching was how to accept life's final chapter with courage, honesty and grace. Michael
                            is inspired by Elia's strength and wishes to continue her selfless devotion to Yoga and strives to
                            support others as they discover their own path to health, happiness and lasting peace.
                        </p>
                        <p>
                            With the support of her partner Marshall the Woy Woy Yoga Space was transferred to Michael
                            under the new name of Precise Yoga. Michael is pleased that many students continue to practice at
                            the new school and continue to honour Elia's devoted commitment to yoga in the Central Coast.
                        </p>
                    </div>
                </TeacherLeft>
                {/* <TeacherRight name={'Teacher2'} /> */}
                <div className='tribute'>
                    {/* <h3>Tribute to Elia</h3> */}
                    <p>
                        “Thank you Elia for being my teacher and encouraging and inspiring me with your devotion
                        and commitment to Yoga, to progress on my own yoga path” - <em>Michael</em>
                    </p>
                </div>
            </Layout>
        );
    }

    function TeacherLeft({ imgSrc, children }) {
        return (
            <div className='teachLeft'>
                <p>{children}</p>
                <img src={imgSrc} className='teacherLeftImg'></img>
            </div>
        );
    }

    function TeacherRight({ name }) {
        return (
            <div className='teachRight'>
                <p>{name}</p>
                <p>[Picture]</p>
            </div>
        );
    }

export default Teachers;
