import '../Styles/App.css';
import { Layout, Quote } from './CommonComponents.js';

function About() {
    return (
        <Layout header={'WHY YOGA?'}>
            <div> 
                <h3>What is yoga?</h3>                
                <p>
                    Yoga is an ancient practice that blends the physical, mental and spiritual into a wholistic philosophy
                    designed to achieve maximum human potential. Although most are aware of the physical postures
                    many would say the main purpose of yoga is much deeper than mere physical exercise.
                </p>                  

                <Quote>
                    <i>
                        'The very essence of Yoga is the practice of asanas and pranayama. Purify the body first,
                        attention to detail trains the mind to focus. Discipline is behind all paths to inner peace
                        and freedom.'
                    </i>
                    <br />
                    <strong>BKS Iyengar - Light on Life</strong>
                </Quote>   

                <img src='/YogaWebsite/chairCropped.jpg' alt='3 people doing yoga poses' style={{width: '100%'}}/>          

                <h3>What to expect at a class</h3>
                <p>
                    Students work at their own capacity and pace, gently progressing towards the more challenging poses.
                </p>
                <p>
                    In each class students follow a varied and carefully orchestrated sequence designed to stretch and
                    strengthen all part of the body in a systematic way. Each class will generally finish with gentle and
                    relaxing restorative poses. Once a basic understanding of the physical poses or asanas is achieved
                    we progress to working with the breath, a practice known as pranayama to focus the mind and
                    achieve a deeper state of awareness.
                </p>
                <p>
                    The approach is both straightforward and practical with an emphasis on correct alignment often
                    using props such as bolsters, chairs blocks and belts to hold the classical poses without strain. This is
                    based on the innovative teaching method developed by world renowned Guru BKS Iyengar in order
                    to make yoga poses available to everyone, allowing students to gain the maximum benefit from
                    whatever level of practice they can comfortably achieve.
                </p>
                
                <Quote>
                    <i>
                        'Physical health and mental happiness are the by-products of yoga.'
                    </i>
                    <br />
                    <strong>BKS Iyengar - Yoga Rahasaya vol 29 No 2 2023</strong>
                </Quote>

                <h3>Benefits of yoga</h3>

                <div className='verticalSplit'>
                    <div className='textLeft'>
                        <p>
                            There are many benefits to practicing yoga but perhaps the most important is that Yoga will leave
                            you feeling restored, calm, balanced and ready to tackle the challenges of everyday life.
                        </p>

                        <p>
                            Yoga is also enjoyable and challenging with endless variety.
                        </p>

                        <p>
                            Most of us are aware that yoga will increase flexibility, but many people are unaware that yoga is
                            also an excellent way to maintain muscular strength, improve balance and is a great way to maintain
                            optimum cardio vascular fitness and health.
                        </p>

                        <p>
                            Yoga is:
                            <ul>
                                <li>Ideal for the young to aid correct development and achieve their maximum potential</li>
                                <li>Great for the middle aged to maintain strength, flexibility and cardio fitness</li>
                                <li>Excellent for those in the later years aiming to literally “stay young”</li>
                            </ul>

                            Yoga has been shown in many scientific studies to:
                            <ul>
                                <li>Improve cardiovascular fitness and reduce breathing difficulties</li>
                                <li>Improve flexibility and strengthen painful joints including many common back pains</li>
                                <li>Manage and prevent osteoporosis</li>
                                <li>Reduce mental stress, depression and anxiety</li>
                                <li>Lose weight</li>
                                <li>Reduce the dependence on drugs - both therapeutic and recreational</li>
                            </ul>
                        </p>
                    </div>
                    <div className='imgRight'>
                        <img src='/YogaWebsite/shoulder.jpg' alt='a person doing a shoulder stand' style={{width: '100%'}}/>   
                    </div>
                </div>               

            </div>
        </Layout>
    );
}

export default About;
